import React from "react";

type Props = {
  color?: string;
  className?: string;
};

const SettingsIcon = ({ color, className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={"none"}
      className={className}
    >
      <path
        d="M3.33333 10C3.33333 11.7954 4.03571 13.5173 5.28595 14.7869C6.5362 16.0565 8.23189 16.7697 10 16.7697C11.7681 16.7697 13.4638 16.0565 14.714 14.7869C15.9643 13.5173 16.6667 11.7954 16.6667 10M3.33333 10C3.33333 8.20456 4.03571 6.48266 5.28595 5.21309C6.5362 3.94352 8.23189 3.23028 10 3.23028C11.7681 3.23028 13.4638 3.94352 14.714 5.21309C15.9643 6.48266 16.6667 8.20456 16.6667 10M3.33333 10H2M16.6667 10H18M16.6667 10H10L6 2.96401M2.48267 12.7774L3.736 12.3143M16.2649 7.68385L17.5182 7.2208M3.872 15.2217L4.89422 14.3516M15.1076 5.64843L16.1289 4.77829M6.00089 17.036L6.66756 15.8626L10.0018 10M13.3342 4.13743L14.0009 2.96401M8.61156 18L8.84267 16.6668M11.1582 3.33318L11.3893 2M11.3893 18L11.1582 16.6668M8.84267 3.33318L8.61156 2M14 17.0351L13.3333 15.8626M16.128 15.2217L15.1067 14.3516M4.89422 5.64752L3.872 4.77739M17.5182 12.7783L16.2649 12.3152M3.73689 7.68476L2.48356 7.2208"
        stroke="currentColor"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;

import React from "react";

const ShopSystemIcon = ({
  color,
  className,
}: {
  color: string;
  className?: string;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2095_4544)">
        <path
          d="M1.6665 5.83342L5.3415 2.15842C5.49655 2.00244 5.68094 1.8787 5.88403 1.79431C6.08712 1.70993 6.30491 1.66657 6.52484 1.66675H13.4748C13.6948 1.66657 13.9126 1.70993 14.1156 1.79431C14.3187 1.8787 14.5031 2.00244 14.6582 2.15842L18.3332 5.83342"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.3335 10.0001V16.6668C3.3335 17.1088 3.50909 17.5327 3.82165 17.8453C4.13421 18.1579 4.55814 18.3335 5.00016 18.3335H15.0002C15.4422 18.3335 15.8661 18.1579 16.1787 17.8453C16.4912 17.5327 16.6668 17.1088 16.6668 16.6668V10.0001"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 18.3335V15.0002C12.5 14.5581 12.3244 14.1342 12.0118 13.8217C11.6993 13.5091 11.2754 13.3335 10.8333 13.3335H9.16667C8.72464 13.3335 8.30072 13.5091 7.98816 13.8217C7.6756 14.1342 7.5 14.5581 7.5 15.0002V18.3335"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6665 5.8335H18.3332"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3332 5.8335V8.3335C18.3332 8.77552 18.1576 9.19945 17.845 9.51201C17.5325 9.82457 17.1085 10.0002 16.6665 10.0002C16.1796 9.97336 15.7146 9.78912 15.3415 9.47516C15.2421 9.40331 15.1225 9.36463 14.9998 9.36463C14.8772 9.36463 14.7576 9.40331 14.6582 9.47516C14.2851 9.78912 13.8201 9.97336 13.3332 10.0002C12.8463 9.97336 12.3813 9.78912 12.0082 9.47516C11.9087 9.40331 11.7892 9.36463 11.6665 9.36463C11.5438 9.36463 11.4243 9.40331 11.3248 9.47516C10.9517 9.78912 10.4867 9.97336 9.99984 10.0002C9.51295 9.97336 9.04795 9.78912 8.67484 9.47516C8.5754 9.40331 8.45585 9.36463 8.33317 9.36463C8.21049 9.36463 8.09094 9.40331 7.9915 9.47516C7.6184 9.78912 7.15339 9.97336 6.6665 10.0002C6.17962 9.97336 5.71461 9.78912 5.3415 9.47516C5.24207 9.40331 5.12252 9.36463 4.99984 9.36463C4.87716 9.36463 4.7576 9.40331 4.65817 9.47516C4.28506 9.78912 3.82006 9.97336 3.33317 10.0002C2.89114 10.0002 2.46722 9.82457 2.15466 9.51201C1.8421 9.19945 1.6665 8.77552 1.6665 8.3335V5.8335"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2095_4544">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShopSystemIcon;
